









// CORE
import { Component, Vue } from 'vue-property-decorator'

// INTERFACES
import { INameValueItem } from '@/store/types'


@Component
export default class PoliciesLayout extends Vue {
  // Список табов
  private items: INameValueItem[] = [
    {
      name: 'Полисы',
      value: 'policies',
    },
    {
      name: 'Страховая статистика',
      value: 'insurance-statistics',
    },
  ]
}
